import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Footer from '../components/footer'
import MainLayout from '../components/layouts/MainLayout'
import SEO from '../components/seo'

import './photos.css'

function PhotosPage() {
  const data = useStaticQuery(graphql`
    {
      lights: file(relativePath: { eq: "title_lights.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      jetable: file(relativePath: { eq: "photos/jetable.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      photobooth: file(relativePath: { eq: "photos/photobooth.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      photographe: file(relativePath: { eq: "photos/photographe.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <MainLayout activeMenu="photos">
      <SEO title="Les photos !" />
      <GatsbyImage
        image={data.lights.childImageSharp.gatsbyImageData}
        className="page-title-lights"
        alt="Dessin d'une guirlande de lumières"
      />
      <h1 className="page-title">
        <span className="first-line">Les</span>
        <br />
        <span className="middle-line">Photos</span>
        <br />
        <span className="last-line">
          (Tu t&apos;es vu quand t&apos;as bu ?)
        </span>
      </h1>

      <p className="paragraph">
        On le dit tout de suite, vous êtiez tous magnifiques !<br />
        Mais bon, plus on avance dans la soirée et... Bref, vous allez bien
        voir.
      </p>

      <div className="gallery-links">
        <div className="gallery-link-container">
          <div>Les appareils jetables</div>
          <GatsbyImage
            image={data.jetable.childImageSharp.gatsbyImageData}
            className="gallery-link-image"
            alt="Dessin d'un appareil photo jetable"
          />
          <div className="gallery-link-description">
            Vous nous avez régalés !<br />
            Encore merci d&apos;avoir joué le jeu !
          </div>
          <a
            href="https://www.amazon.fr/photos/share/WCghNgGfOvc8MmYvDDUTVCmNb4mHkNeu6T9ZxMT6R7h"
            rel="noreferrer"
            target="_blank"
            className="gallery-link-button"
          >
            Voir la gallerie
          </a>
        </div>

        <div className="gallery-link-container">
          <div>Le photobooth</div>
          <GatsbyImage
            image={data.photobooth.childImageSharp.gatsbyImageData}
            className="gallery-link-image"
            alt="Dessin d'une caravane"
          />
          <div className="gallery-link-description">
            Là aussi, certains se sont bien amusés !<br />
            On vous laisse découvrir tout ça :)
          </div>
          <a
            href="https://www.amazon.fr/photos/share/MgdKLIsC7tCBGperAF7PY2MHMYBRZ0sBcoz494iZBmc"
            rel="noreferrer"
            target="_blank"
            className="gallery-link-button"
          >
            Voir la gallerie
          </a>
        </div>

        <div className="gallery-link-container">
          <div>Les photos de Julien</div>
          <GatsbyImage
            image={data.photographe.childImageSharp.gatsbyImageData}
            className="gallery-link-image"
            alt="Dessin d'un photographe"
          />
          <div className="gallery-link-description">
            C&apos;était notre super photographe !<br />
            Tu vas sûrement trouver ta future photo de profil ici ;)
          </div>
          <a
            href="https://www.amazon.fr/photos/share/LrH5rHLK2YbhAfqcozP6mBAdaBlnKjrlPF8qnUYOnX2"
            rel="noreferrer"
            target="_blank"
            className="gallery-link-button"
          >
            Voir la gallerie
          </a>
        </div>
      </div>
      <Footer />
    </MainLayout>
  )
}

export default PhotosPage
